import { Grid, Button, Typography } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiQrcode, mdiQrcodeScan } from "@mdi/js";
import { Link } from "react-router-dom";
import "./styles.css";

function Home() {
  return (
    <div className="container">
      <Typography className="title" variant="h1" style={{ color: '#0b0b0a' }}>
        Sowaapp 
      </Typography>

      <div className="btn-container">
      <h2>CREAR QR</h2>
        <Grid item xs={12} sm={6}>
      
          <Link to="/qr_generator">
            <Button className="btn" variant="contained" size="large" style={{ backgroundColor: '#31A38D', color: '#FFFFFF' }}>
              <Icon
                style={{ padding: 10 }}
                path={mdiQrcode}
                title="QR Generator"
                size={10}
                color="#FFFFFF"
              />
             
            </Button>
          </Link>
        </Grid><h2>LEER QR</h2>
        <Grid item xs={12} sm={6}>
          <Link to="/qr_scanner">
            <Button className="btn" variant="contained" size="large" style={{ backgroundColor: '#31A38D', color: '#FFFFFF' }}>
              <Icon
                style={{ padding: 10 }}
                path={mdiQrcodeScan}
                title="QR Scanner"
                size={10}
                color="#FFFFFF"
              />
            
            </Button>
          </Link>
        </Grid>
      </div>
    </div>
  );
}
export default Home;