import React, {useState} from 'react'
import {Fab, TextareaAutosize} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import { Link } from "react-router-dom";
import QrScan from 'react-qr-reader'

function QRscanner() {

    const [qrscan, setQrscan] = useState('');
    const handleScan = data => {
        if (data) {
            setQrscan(data)
        }
    }
    const handleError = err => {
    console.error(err)
    }

    return (
      <div>
            <Link to="/">
            <Fab style={{marginRight:10}} color="primary">
                <ArrowBack/>
            </Fab>
            </Link>
            <span>ESCANEAR QR</span>
            
            <center>
            <div style={{marginTop:30}}>
                <QrScan
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                  
                    size="medium"
                    variant="outlined"
                    fullWidth
                    color="primary"
                    style={{ height: 240, width: 340,backgroundColor: '#FFFFFF' }}
                />
            </div>
            </center>
<br></br>
            <TextareaAutosize
                
                rowsMax={4}
                defaultValue={qrscan}
                value={qrscan}
                size="medium"
                variant="outlined"
                fullWidth
                color="primary"
                style={{ fontSize:16, width:320, height:100, marginTop:100,backgroundColor: '#FFFFFF' }}
            />
<h2>{qrscan}</h2>
      </div>
    );
  }
  
  export default QRscanner;