import React, { useState } from 'react';
import { Fab, TextField, TextareaAutosize, Grid, Box, Typography } from '@material-ui/core';
import { ArrowBack, GetApp } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import QRcode from 'qrcode.react';

function QRgenerator() {
  const [qr, setQr] = useState('INGRESE AQUÍ EL TEXTO DE SU QR');

  const handleChange = (event) => {
    setQr(event.target.value);
  };

  const downloadQR = () => {
    const canvas = document.getElementById('myqr');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'myqr.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Box p={2} bgcolor="#F5F5F5">
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Link to="/">
            <Fab color="primary">
              <ArrowBack />
            </Fab>
          </Link>
        </Grid>
        <Grid item>
          <Typography variant="h5">CREAR QR</Typography>
        </Grid>
      </Grid>

      <Box mt={4} display="flex" justifyContent="center">
        <TextField
          onChange={handleChange}
          value={qr}
          label="QR"
          size="medium"
          variant="outlined"
          fullWidth
          color="primary"
          style={{ backgroundColor: '#FFFFFF' }}
        />
      </Box>

      <Box mt={4} display="flex" justifyContent="center">
        {qr ? (
          <QRcode id="myqr" value={qr} size={320} includeMargin={true} />
        ) : (
          <Typography></Typography>
        )}
      </Box>

      <Box mt={4} display="flex" justifyContent="center">
        {qr && (
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={8}>
              <TextareaAutosize
                style={{ fontSize: 16, width: '90%', backgroundColor: '#FFFFFF', borderRadius: '5px', padding: '16px', justifyContent:"center"}}
                rowsMax={4}
                defaultValue={qr}
                value={qr}
                placeholder="Mensaje"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex" justifyContent="center">
                <Fab onClick={downloadQR} color="primary">
                  <GetApp />
                </Fab>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default QRgenerator;