import React from 'react'

function Dashboard() {
  const qrs = [
    {id: 1, data: 'https://www.example.com'},
    {id: 2, data: 'https://www.google.com'},
    {id: 3, data: 'https://www.amazon.com'},
  ]

  return (
    <div>
      <h1>QR Codes Generated</h1>
      <ul>
        {qrs.map((qr) => (
          <li key={qr.id}>{qr.data}</li>
        ))}
      </ul>
    </div>
  );
}

export default Dashboard;